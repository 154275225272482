<template>
  <div class="page-grid">
    <category-title :category="category" />
    <div
      v-for="(row, rowIndex) in $ebsn.meta(
        category,
        'template_model.GRID_CONFIG.rows'
      )"
      :key="rowIndex"
      :style="
        `${row.style}; ${
          row.backgroundColor && row.backgroundColor != ''
            ? 'backgroundColor: ' + row.backgroundColor + ';'
            : ''
        }`
      "
    >
      <v-container v-if="row.container" class="container-row">
        <v-row :class="`row-number-${rowIndex}`">
          <ebsn-style
            v-if="row.heights"
            :target="{
              metaData: {
                css: templateHelper.calcHeightStyle(
                  row.heights,
                  `.row-number-${rowIndex}`
                )
              }
            }"
            path="css"
          />
          <v-col
            v-for="(column, columnIndex) in row.cols"
            :key="columnIndex"
            :class="`h-100 col-number-${columnIndex}`"
            :cols="column.xs || 12"
            :sm="column.sm"
            :md="column.md"
            :lg="column.lg"
            :xl="column.xl"
          >
            <template v-if="typeof column.position === 'object'">
              <category-block
                v-for="(position, index) in column.position"
                :key="index"
                :position="position"
                :target="category"
                :container="false"
                class="category-block h-inherit multiple-col"
                :class="
                  index < column.position.length
                    ? `col-num-${index}`
                    : `col-num-${index}`
                "
              />
            </template>
            <category-block
              v-else
              :position="column.position"
              :target="category"
              :container="false"
              class="category-block h-inherit"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-row v-else :class="`row-number-${rowIndex}`" :key="rowIndex">
        <ebsn-style
          v-if="row.heights"
          :target="{
            metaData: {
              css: templateHelper.calcHeightStyle(
                row.heights,
                `.row-number-${rowIndex}`
              )
            }
          }"
          path="css"
        />
        <v-col
          v-for="(column, columnIndex) in row.cols"
          :key="columnIndex"
          :class="`h-100 col-number-${columnIndex}`"
          :cols="column.xs || 12"
          :sm="column.sm"
          :md="column.md"
          :lg="column.lg"
          :xl="column.xl"
        >
          <template v-if="typeof column.position === 'object'">
            <category-block
              v-for="(position, index) in column.position"
              :key="index"
              :position="position"
              :target="category"
              :container="false"
              :class="`category-block h-inherit col-num-${index}`"
            />
          </template>
          <category-block
            v-else
            :position="column.position"
            :target="category"
            :container="false"
            class="category-block h-inherit"
          />
        </v-col>
      </v-row>
    </div>

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_TEXT"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import * as templateHelper from "@/components/category/templateHelper";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import get from "lodash/get";

export default {
  name: "PageGridOld",
  mixins: [category, deliveryReactive, login],
  components: {
    CategoryTitle
  },
  data() {
    return {
      templateHelper: templateHelper
    };
  },
  metaInfo() {
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.category ? this.category.name : ""
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      "Categoria: " + this.category ? this.category.name : ""
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            this.category.metaData?.category_info?.HEADER_IMAGE ||
            this.category.metaData?.category_info?.DESCRIPTION_IMAGE ||
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "coopshop.it"
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
